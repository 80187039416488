<template>
    <div class="module">
      <div class="mod">
        <div class="hd"><div class="tit">热门路线运次排名(月度)</div></div>
        <div class="bd">
          <div class="ranks">
            <!-- <dv-scroll-board :config="config" style="height:280px" /> -->
            <Swiper ref="mySwiper" class="swiper" :options="swiperOption">
              <Swiper-slide class="myswiper-slide" v-for="(item, index) in myswiperSource" :key="'swiepr_item_'+index" :class="'swiper-item-'+index">
                <div class="item">
                  <div class="top">TOP{{index+1}}</div>
                  <div class="start pointer">{{item.start}}</div>
                  <div class="arrow"></div>
                  <div class="end pointer">{{item.end}}</div>
                </div>
              </Swiper-slide>
            </Swiper>

          </div>
        </div>
      </div>
    </div>
</template>

<script>
//import Echart from '@/common/echart';
// import echarts from "echarts";
// import {autoHover} from '@/utils/tool.js';
//import SwiperClass, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.min.css'
import {getAction} from "@/utils/http"
export default {
  name:'hotLines',
  components: {
    //Echart,
    Swiper,
    SwiperSlide,
  },
  data () {
    return {
      options: {},
      dataset:[],
      url:'/trans/routeList',
      swiperOption:{
        direction: 'vertical',
        slidesPerView: 5,
        autoplay:true,
        //loop:true,
      },
      myswiperSource:[]
    };
  },
  mounted () {
    this.init();
  },
  methods: {
    init(){
      getAction(this.url).then(res=>{
        let data = res.data;
        if(data.success){
          let result = data.result, tmpArray = [];
          result.forEach(item=>{
            tmpArray.push({
              start:item.startAddress,
              end:item.endAddress
            })
          })
          this.myswiperSource = tmpArray;
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.module {
    min-width:200px; margin:0px 0 0; max-width:380px;
    .mod {
      .hd {
        background:url('~@/assets/title_bg_left.png') no-repeat center center/contain; width:312px; height:43px;
        .tit {
          padding:12px 10px; font-size:16px; font-weight:bold; color:#06e6e8;
        }
      }
      .bd {
        padding:10px 0 0;
        .ranks {
          height:230px; overflow:hidden;
          .swiper {
            height:230px;
            .myswiper-slide {
              .item {
                display:flex; justify-content:space-between; align-items:center; padding:10px 0;
                .top {
                  background:#0269e9; color:#fff; font-size:12px; padding:6px 6px; line-height:1; border-radius:4px; width:48px; min-width:48px; margin-right:10px; text-align:center;
                }
                .pointer {
                  width:120px; white-space:nowrap; overflow:hidden; text-overflow:ellipsis;
                }
                .arrow {
                  flex:1; margin:0 10px; background:url(~@/assets/arrow_blue.svg) no-repeat right bottom/240px 8px;
                  &:before {
                    content:""; display:block; background:url(~@/assets/truck_blue.svg) no-repeat center center/contain; width:20px; height:19px;
                  }
                }
              }
              &.swiper-item-100 {
                .item{
                  .top{background:#e45f5f;}
                  .arrow {
                    background-image:url(~@/assets/arrow_red.svg);
                    &:before {
                      background-image:url(~@/assets/truck_red.svg);
                    }
                  }
                }

              }
              &.swiper-item-100 {
                .item {
                  .top{background:#ffc100;}
                  .arrow {
                    background-image:url(~@/assets/arrow_yellow.svg);
                    &:before {
                      background-image:url(~@/assets/truck_yellow.svg);
                    }
                  }
                }
              }
              &.swiper-item-200 {
                .item {
                  .top{background:#6aca27;}
                  .arrow {
                    background-image:url(~@/assets/arrow_green.svg);
                    &:before {
                      background-image:url(~@/assets/truck_green.svg);
                    }
                  }
                }

              }
            }
          }

      }
    }
  }
}
</style>
