<template>
  <el-dialog
    :title="title"
    :visible="dialogVisible"
    :modal-append-to-body="false"
    custom-class="diy-dialog"
    width="80%"
    :before-close="closeDialog">
    <div class="bd">
      <div class="box">
        <div class="cell-group">
          <div class="cell-item">
            <div class="item-label">车牌号</div>
            <div class="item-val">{{metaDatas.vehicleNumber}}</div>
          </div>
          <div class="cell-item">
            <div class="item-label">起点</div>
            <div class="item-val">{{metaDatas.startAreaStr}}</div>
          </div>
          <div class="cell-item">
            <div class="item-label">终点</div>
            <div class="item-val">{{metaDatas.endAreaStr}}</div>
          </div>
        </div>
      </div>
      <div id="container" class="map-container">
        <dv-loading>Loading...</dv-loading>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';
import {getAction} from "@/utils/http"
export default {
  name:'routeDetail',
  components: {
    
  },
  data () {
    return {
      dialogVisible:false,
      title:"运单详情",
      map:null,
      url:'/trans/trace',
      metaDatas:{}
    };
  },
  mounted () {
    
  },
  methods: {
    openDialog(data){
      console.log("data:",data)
      this.dialogVisible = true;
      this.metaDatas = data;
      if(!this.GLOBAL.isTrueRoute){
        this.generateRoute(data);
      }else{
        this.fetchData(data.id);
      }
    },
    closeDialog(){
      this.dialogVisible = false;
    },
    generateRoute(data){
      let startLongitude = data.startLongitude, startLatitude = data.startLatitude, endLongitude = data.endLongitude, endLatitude = data.endLatitude;
      let startPoint = [startLongitude, startLatitude];
      let that = this;
      AMapLoader.load({
          key:this.GLOBAL.amapKey,             // 申请好的Web端开发者Key，首次调用 load 时必填
          version:"2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
          plugins:['AMap.ToolBar','AMap.Driving'],      // 需要使用的的插件列表，如比例尺'AMap.Scale'等
          AMapUI:{
            "version": '1.1',
            "plugins":[],
          }
      }).then((AMap)=>{
          this.map = new AMap.Map("container",{  //设置地图容器id
              viewMode:"3D",    //是否为3D地图模式
              mapStyle:'amap://styles/darkblue',
              zoom:8,           //初始化地图级别
              center:startPoint, //初始化地图中心点位置
          });

          AMap.plugin('AMap.TruckDriving', function(){
            let driving = new AMap.TruckDriving({
              policy:9,
              size:2
            });
            var path = [];
            // let startPoint = [startLongitude, startLatitude];
            // let endPoint = [endLongitude, endLatitude];
            path.push({lnglat:[startLongitude, startLatitude]});
            path.push({lnglat:[endLongitude, endLatitude]});

            driving.search(path, function(status, result){

              if(status === 'complete'){
                if(result.routes && result.routes.length){
                  var path = that.parseRouteToPath(result.routes[0]);

									AMapUI.load(['ui/misc/PathSimplifier', 'lib/$'], function(PathSimplifier, $) {
										if (!PathSimplifier.supportCanvas) {
											alert('当前环境不支持 Canvas！');
											return;
										}
										//创建组件实例
										var pathSimplifierIns = new PathSimplifier({
											zIndex:10,
											map:that.map,
											getPath:function(pathData, pathIndex){
												return pathData.path
											},
											renderOptions: {
												//轨迹线的样式
												pathLineStyle: {
													lineWidth: 0,
													fillStyle: null,
													strokeStyle: null,
													borderStyle: null
												},
												// startPointStyle: {
												// radius: 8,
												// fillStyle: '#29e6e7',
												// lineWidth: 2,
												// strokeStyle: '#fff'
												// },
												// endPointStyle: {
												// radius: 8,
												// fillStyle: '#e45f5f',
												// lineWidth: 2,
												// strokeStyle: '#fff'
												// },
												pathNavigatorStyle:{
													width:20,
													height:46,
													content:PathSimplifier.Render.Canvas.getImageContent(require('@/assets/truck.svg'), onload, onerror),
													pathLinePassedStyle:{
														strokeStyle: '#f70',
														lineWidth: 6,
													}
												}
											}
										});
									
										pathSimplifierIns.setData([
										{
											name: '',
											path: path
										}
										]);

										//创建一个巡航器
										var navg0 = pathSimplifierIns.createPathNavigator(0, //关联第1条轨迹
										{
											//loop: true, //循环播放
											speed: 100000
										});

										navg0.start();
									});

                }
              }
            })
          })
          
      }).catch(e=>{
          console.log(e);
      })
    },
    parseRouteToPath(route){
      var path = [];
      for(let i=0, l = route.steps.length; i<l; i++){
        var step = route.steps[i];
        for(let j=0, n=step.path.length; j<n; j++){
          path.push(step.path[j]);
        }
      }
      return path;
    },
    fetchData(id){
      //{id:'1529007813562257410'}
      getAction(this.url, {id:id}).then(res=>{
        console.log("fetchData:", res)
        let data = res.data;
        if(data.success){
          let pathSource = [], coordinateArray = [], startCoords = [];
          pathSource = data.result;
          startCoords = [
            pathSource[Math.trunc(pathSource.length/2)].lon,
            pathSource[Math.trunc(pathSource.length/2)].lat
          ]
          pathSource.forEach(item => {
            coordinateArray.push([
              item.lon,
              item.lat
            ])
          })

          this.initMap(coordinateArray, startCoords);

          
          //启动页面
          //initPath(PathSimplifier);
        }else{
          this.$message.error(data.message);
        }
      })
    },
    initMap(coordsArray, startPoint){
      let that = this;
      AMapLoader.load({
          key:this.GLOBAL.amapKey,             // 申请好的Web端开发者Key，首次调用 load 时必填
          version:"2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
          plugins:['AMap.ToolBar','AMap.Driving'],      // 需要使用的的插件列表，如比例尺'AMap.Scale'等
          AMapUI:{
            "version": '1.1',
            "plugins":[],
          }
      }).then((AMap)=>{
          this.map = new AMap.Map("container",{  //设置地图容器id
              viewMode:"3D",    //是否为3D地图模式
              mapStyle:'amap://styles/darkblue',
              zoom:8,           //初始化地图级别
              center:startPoint, //初始化地图中心点位置
          });
          let infoWindow = null;

          //加载SimpleInfoWindow，loadUI的路径参数为模块名中 'ui/' 之后的部分
          AMapUI.loadUI(['overlay/SimpleInfoWindow'], function(SimpleInfoWindow) {

              infoWindow = new SimpleInfoWindow({
                  infoTitle: '<strong>这里是标题</strong>',
                  infoBody: '<p>这里是内容。</p>'
              });

              //显示在map上
              //infoWindow.open(that.map, that.map.getCenter());
          });

          AMapUI.load(['ui/misc/PathSimplifier', 'lib/$'], function(PathSimplifier, $){
            if (!PathSimplifier.supportCanvas) {
              alert('当前环境不支持 Canvas！');
              return;
            }
            console.log("pathsimpli....")
            //创建组件实例
            var pathSimplifierIns = new PathSimplifier({
                zIndex: 10,
                map: that.map, //所属的地图实例
                getPath: function(pathData, pathIndex) {
                    //返回轨迹数据中的节点坐标信息，[AMap.LngLat, AMap.LngLat...] 或者 [[lng|number,lat|number],...]
                    return pathData.path;
                },
                getHoverTitle: function(pathData, pathIndex, pointIndex) {
                    //返回鼠标悬停时显示的信息
                    if (pointIndex >= 0) {
                        //鼠标悬停在某个轨迹节点上
                        return pathData.name + '，点:' + pointIndex + '/' + pathData.path.length;
                    }
                    //鼠标悬停在节点之间的连线上
                    return pathData.name + '，点数量' + pathData.path.length;
                },
                renderOptions: {
                    //轨迹线的样式
                    pathLineStyle: {
                        strokeStyle: '#f70',
                        lineWidth: 6,
                        dirArrowStyle: true
                    },
                    startPointStyle: {
                      radius: 8,
                      fillStyle: '#29e6e7',
                      lineWidth: 2,
                      strokeStyle: '#fff'
                    },
                    endPointStyle: {
                      radius: 8,
                      fillStyle: '#e45f5f',
                      lineWidth: 2,
                      strokeStyle: '#fff'
                    },
                    pathNavigatorStyle:{
                      width:20,
                      height:46,
                      content:PathSimplifier.Render.Canvas.getImageContent(require('@/assets/truck.svg'), onload, onerror),
                      pathLinePassedStyle:{
                        strokeStyle: '#6aca27',
                        lineWidth: 6,
                      }
                    }
                }
            });

            pathSimplifierIns.setData([
              {
                name: '测试',
                path: coordsArray
              }
            ]);

            //创建一个巡航器
            var navg0 = pathSimplifierIns.createPathNavigator(0, //关联第1条轨迹
              {
                  loop: true, //循环播放
                  speed: 30000
              });

            navg0.on("moving",()=>{
              //.setPosition(navgtr.getPosition());
              infoWindow.open(that.map, navg0.getPosition());
            })

            navg0.start();

          });
      }).catch(e=>{
          console.log(e);
      })
  },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__wrapper {z-index:10000000 !important;}
::v-deep .diy-dialog {
  background:#0b3270; border:#207eba 1px solid; 
  .el-dialog__header {
    border-bottom:#207eba 1px solid; padding:20px;
    .el-dialog__title {
      color:#29e6e7;
    }
  }
}
.bd {
  margin:-30px -20px; display:flex; justify-content:space-between; align-items:stretch;
  .box {
    width:200px;
    .cell-group {
      padding:10px;
      .cell-item {
        display:flex; justify-content:flex-start; align-items:flex-start; color:#29e6e7; padding:7px 0;
        .item-label {margin-right:8px; opacity:.7; min-width:3.2em;}
        .item-val {}
      }
    }
  }
  .map-container {
    flex:1; height:800px; width:100%;
  }
}
</style>
