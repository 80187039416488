<template>
    <div class="module">
      <div class="mod">
        <div class="hd"><div class="tit">运单监控</div></div>
        <div class="bd">
          <div class="conical-chart">
            <dv-scroll-board :config="config" @click="openDetail" style="height:280px" />
          </div>
        </div>
      </div>
    </div>
</template>

<script>
//import Echart from '@/common/echart';
// import echarts from "echarts";
// import {autoHover} from '@/utils/tool.js';
import {getAction} from "@/utils/http"
export default {
  name:'routes',
  components: {
    //Echart,
  },
  data () {
    return {
      options: {},
      dataset:[],
      url:'/trans/orderList',
      goodTypeSource:[
        {name:'煤炭及制品', value:'0100'},{name:'石油、天然气及制品', value:'0200'},{name:'金属矿石', value:'0300'},{name:'钢铁', value:'0400'},{name:'矿建材料', value:'0500'},{name:'水泥', value:'0600'},{name:'木材', value:'0700'},{name:'非金属矿石', value:'0800'},{name:'化肥及农药', value:'0900'},{name:'盐', value:'1000'},{name:'粮食', value:'1100'},{name:'机械、设备、电器', value:'1200'},{name:'轻工原料及制品', value:'1300'},{name:'有色金属', value:'1400'},{name:'轻工医药产品', value:'1500'},{name:'鲜活农产品', value:'1601'},{name:'冷藏冷冻货物', value:'1602'},{name:'商品汽车', value:'1701'},{name:'其他', value:'1700'}
      ],
      resultSource:[],
      config:{},
    };
  },
  mounted () {
    this.init();
  },
  methods: {
    init(){
      getAction(this.url).then(res=>{
        let data = res.data;
        if(data.success){
          let result = data.result.records, tmpArray = [];
          this.resultSource = result;
          result.forEach(item=>{
            let statusStr = '';

                statusStr = '<span style="color:#ffffff;">'+item.status_dictText+'</span>';
            // switch(item.status){
              // case 2:
              //   statusStr = '<span style="color:#6aca27;">'+item.status_dictText+'</span>';
              //   break;
              // case 4:
              //   statusStr = '<span style="color:#e45f5f;">'+item.status_dictText+'</span>';//待支付
              //   break;
              // case 5:
              //   statusStr = '<span style="color:#6aca27;">'+item.status_dictText+'</span>';//已完成
              //   break;
              // default:
              //   statusStr = '<span style="color:#0269e9;">'+item.status_dictText+'</span>';//运输中
              //   break;
            // }
            tmpArray.push([
              item.vehicleNumber,
              item.goodsType_dictText,
              item.startAddress,
              item.endAddress,
              statusStr
            ])
          })
          this.config = {
            headerHeight:40,
            rowNum:6,
            headerBGC:'#10305f',
            oddRowBGC:'tranparent',
            evenRowBGC:'#0a1a3a',
            header:['车牌号','货物名称','起点','终点','状态'],
            data:tmpArray
          }
        }
      })
    },
    openDetail(row){
      console.log("row:",row);
      let item = this.resultSource[row.rowIndex];
      this.$emit("detail", item)
    },
  }
}
</script>

<style lang="scss" scoped>
.module {
    min-width:200px; margin:0px 0 0;
    .mod {
      .hd {
        background:url('~@/assets/title_bg_right.png') no-repeat center right/contain; height:43px; margin-bottom:10px;
        .tit {
          padding:12px 10px; text-align:right; font-size:16px; font-weight:bold; color:#06e6e8;
        }
      }
      .bd {
        .conical-chart {

        }
      }
    }
}
</style>
