<template>
    <div class="module">
      <div class="mod">
        <div class="hd"><div class="tit">月增长趋势</div></div>
        <div class="bd">
          <Echart
            :options="options"
            id="trendByChart"
            height="280px"
            width="100%"
            ></Echart>
        </div>
      </div>
    </div>
</template>

<script>
import Echart from '@/common/echart';
import echarts from "echarts";
import {autoHover} from '@/utils/tool.js';
import {getAction} from "@/utils/http"
export default {
  name:'driverLines',
  components: {
    Echart,
  },
  data () {
    return {
      options: {},
      dataset:[],
      url:'/trans/monthTrend',
    };
  },
  mounted () {
    this.setData();
  },
  methods: {
    // 根据自己的业务情况修改
    setData () {
      getAction(this.url).then(res=>{
        let data = res.data;
        if(data.success){
          let result = data.result;
          let source = result.driverIncrease, tmpArray = [];
          tmpArray.push(["product","司机"])
          source.forEach(item=>{
            tmpArray.push([
              item.censusDate,
              item.number
            ])
          });

          let myChart = echarts.init(document.getElementById("trendByChart"));
          this.dataset = tmpArray;

          let newData = this.dataset[0];
          let seriesType = [];
          for(let i=0; i<newData.length-1; i++){
            seriesType.push({type:'line'});
          }

          this.options = {
                title: {
                  text: "",
                },
                tooltip: {
                  trigger: "axis",
                  backgroundColor: "rgba(255,255,255,0.1)",
                  axisPointer: {
                    type: "shadow",
                    label: {
                      show: true,
                      backgroundColor: "#7B7DDC"
                    }
                  },
                  extraCssText:'z-index:1998'
                },
                legend: {
                  icon:'circle',
                  textStyle: {
                    color: "#B4B4B4"
                  },
                  top: "2%"
                },
                grid: {
                  x: "8%",
                  width: "88%",
                  y: "4%"
                },
                dataset:{
                  source:this.dataset
                },
                xAxis: {
                  type: 'category',
                },
                yAxis: {
                  type: 'value'
                },
                series: seriesType
            }
            myChart.setOption(this.options, true);
            this.tootipTimer && this.tootipTimer.clearLoop(); // this.tootipTimer 在data里定义
            this.tootipTimer = 0;
            let num = this.dataset.length-1;
            this.tootipTimer = autoHover(myChart, this.options, num, 3000);
        }
      })
      
      
      
      

      // this.dataset = [
      //   ["product","司机","车辆"],
      //   ["11/16",243,344],
      //   ["11/17",345,123],
      //   ["11/18",355,453],
      //   ["11/19",465,344],
      //   ["11/20",575,234],
      //   ["11/21",656,231],
      //   ["11/22",678,123]
      // ];
      

      // getAction("/sapi/marketByCategory.json").then(res=>{
      //   this.dataset = res.data.dataset;
      //   let newData = this.dataset[0];
      //   let seriesType = [];
      //   for(let i=0; i<newData.length-1; i++){
      //     seriesType.push({type:'line'});
      //   }
        
      // });
      
    },
  }
}
</script>

<style lang="scss" scoped>
.module {
    min-width:200px; padding:20px 0 0;
    .mod {
      .hd {
        background:url('~@/assets/title_bg_right.png') no-repeat center right/contain; height:43px;
        .tit {
          padding:12px 10px; text-align:right; font-size:16px; font-weight:bold; color:#06e6e8;
        }
      }
      .bd {
        .data-pane {
          .data-total {
            padding:20px 0;
            .data-title {
              font-size:16px; font-weight:bold; padding:0 0 10px;
            }
            .data-con {
              font-size:36px; color:#ff0; font-weight:bold;
            }
          }
          .data-box {
            background:#102556 url(~@/assets/split_horizontal.png) no-repeat center center/334px 1px; border:#1e8ee8 1px solid; display:flex; justify-content: space-between; align-items:center; flex-wrap:wrap;
            .data-item {
              width:33%; padding:20px 0; background:url(~@/assets/split_vertical.png) no-repeat right center/1px 41px;
              .item-num {text-align:center; font-size:24px; font-weight:bold; color:#ff0; padding:0 0 10px;}
              .item-title {text-align:center;}
              &:nth-child(3n) {background:none;}
            }
            
          }
        }
      }
    }
}
.rankboard {
  ::v-deep.ranking-column {
    ::v-deep.inside-column {background:#ff0;}
  }
}
</style>
