<template>
    <div class="module">
      <div class="mod">
        <div class="bd">
          <div class="data">
            <div class="data-item">
              <div class="item-num">{{ownerTotal==null?0:ownerTotal}}</div>
              <div class="item-title">累计货主</div>
            </div>
            <div class="data-item">
              <div class="item-num">{{driverTotal==null?0:driverTotal}}</div>
              <div class="item-title">累计司机</div>
            </div>
            <div class="data-item">
              <div class="item-num">{{truckTotal==null?0:truckTotal}}</div>
              <div class="item-title">累计车辆</div>
            </div>
            <div class="data-item">
              <div class="item-num">{{routesTotal==null?0:routesTotal}}</div>
              <div class="item-title">累计线路</div>
            </div>
            <div class="data-item">
              <div class="item-num">{{weightTotal==null?0:weightTotal}}</div>
              <div class="item-title">年度累计发运量(吨)</div>
            </div>
            <div class="data-item">
              <div class="item-num">{{monthAvgTotal==null?0:monthAvgTotal}}</div>
              <div class="item-title">月均运单量</div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import {getAction} from '@/utils/http';
export default {
  name:'statistics',
  components: {

  },
  data () {
    return {
      url:'/trans/analysis',
      ownerTotal:0,
      driverTotal:0,
      truckTotal:0,
      routesTotal:0,
      weightTotal:0,
      monthAvgTotal:0,
    };
  },
  mounted () {
    this.init();
  },
  methods: {
    init(){
      getAction(this.url).then((res)=>{
        let data = res.data;
        if(data.success){
          let result = data.result;
          this.ownerTotal = result.comp.number;
          this.driverTotal = result.truck.number;
          this.truckTotal = result.car.number;
          this.routesTotal = result.route.number;
          this.weightTotal = result.weight.sumWeight;
          this.monthAvgTotal = result.monthAvg.number;
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.module {
    min-width:200px; padding:15px 0 0;
    .mod {
      .hd {
        .tit {
          font-size:16px; font-weight:bold; color:#06e6e8; line-height:1; text-align:center;
        }
      }
      .bd {
        .data {
          display:flex; justify-content:center; align-items:center;
          .data-item {
            margin:0 10px;
            .item-num {
              background:url(~@/assets/icon.png) no-repeat center center/contain; width:120px; height:68px; text-align:center; font-size:20px; font-weight:bold; color:#06e6e8; box-sizing:border-box; padding:10px 0 0;
            }
            .item-title {
              text-align:center; color:#06e6e8; font-size:12px; padding:5px 0 0;
            }
          }
        }
      }
    }
}
</style>
