<template>
    <div class="module">
        <div class="hd"><div class="tit">每日发货量</div></div>
        <div class="bd">
            <Echart
            :options="options"
            id="purchaseBar"
            height="280px"
            width="100%"
            ></Echart>
        </div>
    </div>
</template>

<script>
import Echart from '@/common/echart';
import echarts from "echarts";
import {autoHover} from '@/utils/tool.js';
import {getAction} from "@/utils/http"
export default {
  name:'goodsBar',
  components: {
    Echart,
  },
  data () {
    return {
      url:'/trans/dayWeight',
      options: {},
      dataset:[],
    };
  },
  mounted () {
    this.setData();
  },
  methods: {
    // 根据自己的业务情况修改
    setData () {
      getAction(this.url).then(res=>{
        let data = res.data;
        if(data.success){
          let result = data.result, tmpArray = [["product","发货量"]];
          let myChart = echarts.init(document.getElementById("purchaseBar"));
          result.forEach(item => {
            tmpArray.push([
              item.reportDt,
              item.sumWeight
            ])
          });
          this.dataset = tmpArray;
          let newData = this.dataset[0];
          let seriesType = [];
          for(let i=0; i<newData.length-1; i++){
            seriesType.push({type:'bar', barWidth:10});
          }

          this.options = {
                title: {
                  text: "",
                },
                tooltip: {
                  trigger: "axis",
                  backgroundColor: "rgba(255,255,255,0.1)",
                  axisPointer: {
                    type: "shadow",
                    label: {
                      show: true,
                      backgroundColor: "#7B7DDC"
                    }
                  },
                  extraCssText:'z-index:1998'
                },
                // legend: {
                //   icon:'circle',
                //   textStyle: {
                //     color: "#B4B4B4"
                //   },
                //   top: "2%"
                // },
                grid: {
                  x: "8%",
                  width: "88%",
                  y: "4%"
                },
                dataset:{
                  source:this.dataset
                },
                xAxis: {
                  type: 'category',
                },
                yAxis: {
                  type: 'value'
                },
                series: seriesType
            }
            myChart.setOption(this.options, true);
            this.tootipTimer && this.tootipTimer.clearLoop(); // this.tootipTimer 在data里定义
            this.tootipTimer = 0;
            let num = this.dataset.length-1;
            this.tootipTimer = autoHover(myChart, this.options, num, 3000);
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.module {
    min-width:200px; padding:20px 0 0;
    .hd {
      background:url('~@/assets/title_bg_left.png') no-repeat center center/contain; width:312px; height:43px;
      .tit {
        padding:12px 10px; font-size:16px; font-weight:bold; color:#06e6e8;
      }
    }
    .bd {padding:15px 0 0;}
}
</style>
