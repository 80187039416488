<template>
  <div id="index" ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex jc-center" style="height:80px;">
          <!-- <dv-decoration-10 class="dv-dec-10" /> -->
          <div class="d-flex jc-center">
            <!-- <dv-decoration-8 class="dv-dec-8" :color="['#568aea', '#000000']" /> -->
            <div class="title" style="padding:20px 0 0;">
              <span class="text" style="font-size:36px; font-weight:bold; background-image:-webkit-linear-gradient(bottom,#b9cfff,#ffffff); -webkit-background-clip:text; -webkit-text-fill-color:transparent; box-shadow:0 0 10px rgba(131,192,255,.1);">
                {{title}}
              </span>
            </div>
          </div>
        </div>

        <div class="d-flex jc-between row-box index-body">
          <div class="left column-box">
            <owner-top></owner-top>
            <goods-bar></goods-bar>
            <hot-lines></hot-lines>
          </div>
          <div class="center column-box">
            <div class="total" style="position:absolute; left:0; right:0; top:0; z-index:1;">
              <gmv></gmv>
            </div>
            <div>
              <map-center></map-center>
            </div>
            <div>
              <statistics></statistics>
            </div>
          </div>
          <div class="right column-box">
            <driver-top></driver-top>
            <driver-lines></driver-lines>
            <routes @detail="openDialog"></routes>
          </div>
        </div>
      </div>
    </div>
    <route-detail ref="routeDetail" v-if="defaultMap=='amap'"></route-detail>
    <route-b-detail ref="routeDetail" v-if="defaultMap=='bmap'"></route-b-detail>
  </div>
</template>

<script>
import '@/assets/fonts/fonts.css'
import drawMixin from "../utils/drawMixin";
import { formatTime } from '../utils/index.js'
import MapCenter from '@/views/modules/mapCenter.vue';

import ownerTop from '@/views/modules/ownerTop';
import driverTop from '@/views/modules/driverTop';
import gmv from '@/views/modules/gmv';
import routes from './modules/routes.vue';
import driverLines from './modules/driverLines.vue';
import hotLines from './modules/hotLines.vue';
import GoodsBar from './modules/goodsBar.vue';
import Statistics from './modules/statistics.vue';
import RouteDetail from './modules/routeDetail.vue';
import routeBDetail from '@/views/modules/routeBDetail';

export default {
  mixins: [ drawMixin ],
  data() {
    return {
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
      defaultMap:this.GLOBAL.defaultMap,
      title:this.GLOBAL.title,
    }
  },
  components: {
    MapCenter,
    ownerTop,
    driverTop,
    gmv,
    routes,
    driverLines,
    hotLines,
    GoodsBar,
    Statistics,
    RouteDetail,
    routeBDetail,
  },
  mounted() {
    //this.timeFn()
    //this.openDialog({id:123});
    this.cancelLoading()
  },
  unMounted() {
    //clearInterval(this.timing)
  },
  methods: {
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), 'HH: mm: ss')
        this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
        this.dateWeek = this.weekday[new Date().getDay()]
      }, 1000)
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    openDialog(params){
      console.log("open:", params)
      this.$refs.routeDetail.openDialog(params);
    }
  }
}
</script>

<style lang="scss">
@import '../assets/scss/index.scss';
</style>
