<template>
  <el-dialog
    :title="title"
    :visible="dialogVisible"
    :modal-append-to-body="false"
    custom-class="diy-dialog"
    width="80%"
    :before-close="closeDialog">
    <div class="bd">
      <div class="box">
        <div class="cell-group">
          <div class="cell-item">
            <div class="item-label">车牌号</div>
            <div class="item-val">{{metaDatas.vehicleNumber}}</div>
          </div>
          <div class="cell-item">
            <div class="item-label">起点</div>
            <div class="item-val">{{metaDatas.startAreaStr}}</div>
          </div>
          <div class="cell-item">
            <div class="item-label">终点</div>
            <div class="item-val">{{metaDatas.endAreaStr}}</div>
          </div>
        </div>
      </div>  
      <div id="container" class="map-container">
        <!-- <dv-loading>Loading...</dv-loading> -->
        <baidu-map class="bm-view" 
          :ak="bmapKey" 
          :zoom="bmapOptions.zoom" 
          :center="bmapOptions.center" 
          :scroll-wheel-zoom="true" 
          :map-style="bmapOptions.mapStyle"
          @ready="mapReady"
        >
          <bml-lushu :play="play" :icon="icon" :path="path" v-if="path.length>0" :auto-view="true"  :rotation="true" :speed="8000"></bml-lushu>
          <bm-marker :position="item.position" :icon="item.icon" v-for="(item, index) in markers" :key="'bm_marker_item_'+index"></bm-marker>
          <bm-driving :start="startPoint" :end="endPoint" :auto-viewport="true" :panel="false" v-if="!isTrueRoute" @searchcomplete="handleSearchComplete"></bm-driving>
          <bm-polyline :path="path" v-if="path.length>0 && isTrueRoute" stroke-color="#ffc100" :stroke-weight="6" :editing="false" :stroke-opacity="1"></bm-polyline>
        </baidu-map>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import {BmPolyline, BmMarker, BmDriving, BmlLushu} from 'vue-baidu-map'
import {getAction} from "@/utils/http";
import styleJson from '@/utils/mapStyle.json';
export default {
  name:'routeBDetail',
  components: {
    BaiduMap,
    BmPolyline,
    BmMarker,
    BmlLushu,
    BmDriving,
  },
  data () {
    return {
      dialogVisible:false,
      title:"运单详情",
      map:null,
      BMap:null,
      convertor:null,
      points:[],
      formatPoints:[],
      formatData:[],
      url:'/trans/trace',
      bmapKey:this.GLOBAL.bmapKey,
      isTrueRoute:this.GLOBAL.isTrueRoute,
      metaDatas:{},
      markers:[],
      bmapOptions:{
        zoom:10,
        center:{},
        // mapStyle:{
        //   //styleId:'e82cdd4c16cd580be0caeff244dbc3c9',
        //   styleJson: styleJson
        // }
      },
      startPoint:null,
      endPoint:null,
      play:true,
      icon: {
        url: require('@/assets/truck_baidu.png'),
        size: {width: 46, height: 21},
        // size: {width: 18, height: 40},
        //opts: {anchor: {width: 27, height:13}}
      },
      path: [],
    };
  },
  mounted () {
    
  },
  methods: {
    openDialog(data){
      this.dialogVisible = true;
      this.metaDatas = data;
      //this.buildMarker(this.path);
      if(!this.GLOBAL.isTrueRoute){
        this.generateRoute(data);
      }else{
        this.fetchData(data.id);
      }
    },
    closeDialog(){
      this.dialogVisible = false;
    },
    buildMarker(path){
      this.markers.push({
        position:{
          lng:Number(path[0].lon),
          lat:Number(path[0].lat)
        },
        offset:{
          width:0,
          height:-20,
        },
        icon:{
          url:require('@/assets/start.svg'),
          size:{width:34, height:40}
        }
      });
      this.markers.push({
        position:{
          lng:Number(path[path.length-1].lon),
          lat:Number(path[path.length-1].lat)
        },
        offset:{
          width:0,
          height:-20,
        },
        icon:{
          url:require('@/assets/end.svg'),
          size:{width:34, height:40}
        }
      });
      console.log("marks:",this.markers);
    },
    fetchData(id){
      // this.initMap();
      // return;
      // getAction(this.url, {id:'1531570803993821185'}).then(res=>{
      getAction(this.url, {id:id}).then(res=>{
        console.log("this.path:", this.path)
        let data = res.data;
        if(data.success){
          let pathSource = [], coordinateArray = [], startCoords = [];
          pathSource = data.result;
          // this.formatData = pathSource;
          // this.transformationPoint();
          // return;
          pathSource.forEach(item=>{
            coordinateArray.push({
              lng:Number(item.lon),
              lat:Number(item.lat)
            })
          });
          this.path = coordinateArray;
          console.log("this.path2:",this.path)
          this.bmapOptions.center = {
            lng:Number(pathSource[Math.trunc(pathSource.length/2)].lon),
            lat:Number(pathSource[Math.trunc(pathSource.length/2)].lat)
          }
          this.buildMarker(pathSource);
          // startCoords = [
          //   pathSource[Math.trunc(pathSource.length/2)].lon,
          //   pathSource[Math.trunc(pathSource.length/2)].lat
          // ]
          // pathSource.forEach(item => {
          //   coordinateArray.push([
          //     item.lon,
          //     item.lat
          //   ])
          // })

          //this.initMap(coordinateArray, startCoords);

          
          //启动页面
          //initPath(PathSimplifier);
        }
      })
    },
    
    initMap(){
      let that = this;
      
    },
    generateRoute(data){
      //根据线路规划生成路径
      let startLongitude = data.startLongitude, startLatitude = data.startLatitude, endLongitude = data.endLongitude, endLatitude = data.endLatitude;
      this.startPoint = {lng:parseFloat(startLongitude), lat:parseFloat(startLatitude)};
      this.endPoint = {lng:parseFloat(endLongitude), lat:parseFloat(endLatitude)};
      let that = this;
      that.bmapOptions.center = {
        lng:Number(startLongitude),
        lat:Number(startLatitude)
      };
    },
    handleSearchComplete(res){
      this.path = res.getPlan(0).getRoute(0).getPath();
    },
    async getMapData(){},
    async transformationPoint(){
      let newArr = [], that = this;
      //console.log("transformationPoint:",this.formatData)
      this.formatData.forEach((item, i)=>{
        that.convertor.translate(that.formatData[i], 3, 5, data=>{
          console.log("dat:",data)
          if(data.status === 0){
            data.points.forEach(e=>{
              newArr.push(e);
            })
            console.log("new array:", newArr);
          }
        })
      })
    },
    async mapReady({BMap, map}){
      console.log("mapready")
      this.map = map;
      this.BMap = BMap;
      this.convertor = new BMap.Convertor();
      await this.getMapData();
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__wrapper {z-index:10000000 !important;}
::v-deep .diy-dialog {
  background:#0b3270; border:#207eba 1px solid; 
  .el-dialog__header {
    border-bottom:#207eba 1px solid; padding:20px;
    .el-dialog__title {
      color:#29e6e7;
    }
  }
}
.bd {
  margin:-30px -20px; display:flex; justify-content:space-between; align-items:stretch;
  .box {
    width:200px;
    .cell-group {
      padding:10px;
      .cell-item {
        display:flex; justify-content:flex-start; align-items:flex-start; color:#29e6e7; padding:7px 0;
        .item-label {margin-right:8px; opacity:.7; min-width:3.2em;}
        .item-val {}
      }
    }
  }
  .map-container {
    flex:1; height:800px; width:100%;
    .bm-view {height:800px; width:100%;}
  }
}
</style>
