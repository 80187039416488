<template>
    <div class="module">
      <div class="mod">
        <div class="hd"><div class="tit">司机TOP10</div></div>
        <div class="bd">
          <dv-scroll-ranking-board class="rankboard" :config="config" style="height:240px" />
        </div>
      </div>
    </div>
</template>

<script>
//import Echart from '@/common/echart';
// import echarts from "echarts";
// import {autoHover} from '@/utils/tool.js';
import {getAction} from "@/utils/http"
export default {
  name:'driverTop',
  components: {
    //Echart,
  },
  data () {
    return {
      options: {},
      dataset:[],
      url:'/trans/driver/top10',
      config:{
        
        data:[],
      },
    };
  },
  mounted () {
    this.$nextTick(()=>{
      this.init();
    })
  },
  methods: {
    init(){
      getAction(this.url).then((res)=>{
        let data = res.data;
        if(data.success){
          let result = data.result;
          let source = result.receipt, tmpArray = [];
          source.forEach(item => {
            tmpArray.push({
              name:item.driverName,
              value:item.orderAmount,
            })
          });
          this.config = {
            rowNum:4,
            data:tmpArray
          }
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.module {
    min-width:200px;
    .mod {
      .hd {
        background:url('~@/assets/title_bg_right.png') no-repeat center right/contain; height:43px;
        .tit {
          padding:12px 10px; text-align:right; font-size:16px; font-weight:bold; color:#06e6e8;
        }
      }
      .bd {
        .data-pane {
          .data-total {
            padding:20px 0;
            .data-title {
              font-size:16px; font-weight:bold; padding:0 0 10px;
            }
            .data-con {
              font-size:36px; color:#ff0; font-weight:bold;
            }
          }
          .data-box {
            background:#102556 url(~@/assets/split_horizontal.png) no-repeat center center/334px 1px; border:#1e8ee8 1px solid; display:flex; justify-content: space-between; align-items:center; flex-wrap:wrap;
            .data-item {
              width:33%; padding:20px 0; background:url(~@/assets/split_vertical.png) no-repeat right center/1px 41px;
              .item-num {text-align:center; font-size:24px; font-weight:bold; color:#ff0; padding:0 0 10px;}
              .item-title {text-align:center;}
              &:nth-child(3n) {background:none;}
            }
            
          }
        }
      }
    }
}
.rankboard {
  ::v-deep.ranking-column {
    ::v-deep.inside-column {background:#ff0;}
  }
}
</style>
